var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _c("tr", [
          _c("th", [_vm._v("Dynamic Data")]),
          _c(
            "td",
            [
              _c("e-auto-complete", {
                staticClass: "auto-input",
                attrs: { values: _vm.dynamicItems },
                on: { update: _vm.dynamicSearchPlace },
                model: {
                  value: _vm.test,
                  callback: function ($$v) {
                    _vm.test = $$v
                  },
                  expression: "test",
                },
              }),
              _vm._v(" " + _vm._s(_vm.test) + " "),
            ],
            1
          ),
          _vm._m(1),
          _c(
            "td",
            [
              _c("e-auto-complete", {
                staticClass: "auto-input",
                attrs: { values: _vm.staticItems },
                model: {
                  value: _vm.test,
                  callback: function ($$v) {
                    _vm.test = $$v
                  },
                  expression: "test",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("Static Data"),
      _c("p", { staticStyle: { color: "red" } }, [_vm._v("(기능 확인필요)")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }