<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col width="140">
        <col>
        <col width="140">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <th>Dynamic Data</th>
          <td>
            <e-auto-complete class="auto-input"
                     @update="dynamicSearchPlace"
                     v-model="test"
                     :values="dynamicItems"
                     >
            </e-auto-complete>
            {{ test }}
          </td>
          <th>Static Data<p style='color:red;'>(기능 확인필요)</p></th>
          <td>
            <e-auto-complete class="auto-input"
                     v-model="test"
                     :values="staticItems"
                     >
            </e-auto-complete>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import apiCommons from '@/api/services/commons'
import EAutoComplete from '@/components/common/EAutoComplete'

export default {
  name: 'SampleAutoComplete',
  components: {
    EAutoComplete
  },
  data: function () {
    return {
      test: '',
      dynamicItems: [],
      staticItems: ['서울', '대전', '대구', '부산', 'Seoul', 'Daejeon', 'Daegu', 'Busan']
    }
  },
  methods: {
    dynamicSearchPlace: function (e) {
      console.log('dynamicSearchPlace ' + e)
      const THIS = this
      const keyword = e

      apiCommons.getCommonsPlaces(keyword).then((data) => {
        const list = data.data
        if (list === undefined) {
          THIS.dynamicItems = []
        } else {
          THIS.dynamicItems = list.map(vo => vo.plcEnm)
        }
      })
    }
  }
}

</script>
